import request from '@/utils/request.js';

export function loveCodeGroupUserList() {
    return request({
        url: `${process.env.VUE_APP_BASEURL_API2}/we/work/groupUserList`,
        method: 'GET'
    });
}

export function getAllEmployeesList() {
    return request({
        url: `${process.env.VUE_APP_BASEURL_API2}/we/work/allEmployList`,
        method: 'GET'
    });
}



export function getLableList() {
    return request({
        url: `${process.env.VUE_APP_BASEURL_API2}/we/work/getLableList`,
        method: 'GET'
    });
}

export function getCorpTag() {
    return request({
        url: `${process.env.VUE_APP_BASEURL_API2}/we/work/getCorpTag`, method: 'get'
    })
}




