<body>
</body>
<template>
  <div class="app-container">


    <el-dialog
        title="提示"
        :visible.sync="deletePrivateDomain"
        width="30%"
    >
      <span>确定删除?</span>
      <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="deleteCode">确 定</el-button>
         </span>
    </el-dialog>

    <el-row :gutter="20">
      <el-col :span="5">
        <el-card>
          <div slot="header" style="display: flex;flex-direction: row;justify-content: space-between">
            <span>活码组</span>

            <div style="cursor: pointer" @click="dialogVisible =  true">
              <i class="el-icon-plus"/>
            </div>
          </div>
          <el-tree @node-click="getListByGroupId" :data="groupData" node-key="id" :default-expanded-keys="[0]"
                   :props="defaultProps">

            <span
                style="display: flex;flex-direction: row;justify-content: space-between;width: 120%;align-items: center;"
                slot-scope="{ node, data }">
              <span style="max-width: 150px;overflow: hidden;text-overflow: ellipsis;">{{ node.label }}</span>
              <span v-if="data.id !== 0">
                <el-button
                    type="text"
                    size="mini"
                    @click="() => append(data)">
                  编辑
                </el-button>
                <el-button
                    type="text"
                    size="mini"
                    @click="() => remove(node, data)">
                  删除
                </el-button>
              </span>
            </span>

          </el-tree>
        </el-card>
      </el-col>

      <el-col :span="18">

        <el-form :model="queryParams" :rules="searchRules" ref="queryForm" size="mini" :inline="true"
                 label-width="68px">
          <el-form-item label="名称" prop="liveCodeName">
            <el-input v-model="queryParams.liveCodeName" placeholder="请输入活码" clearable/>
          </el-form-item>

          <el-form-item label="使用员工" prop="userIds">
            <commonSelect v-model="queryParams.userIds" showCheckedTagDiv="hide" mode="选择员工"/>
          </el-form-item>

          <el-form-item label="创建时间" prop="createTime">
            <el-date-picker
                v-model="createTime"
                type="datetimerange"
                align="right"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd HH:mm:ss"
                :default-time="['00:00:00', '23:59:59']"
                @change="createTimeChange"
            >
            </el-date-picker>
          </el-form-item>

          <el-form-item>
            <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">查询</el-button>
            <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
          </el-form-item>
          <el-button style="float: right" type="primary" size="mini" @click="ChannelAdd">新增活码</el-button>
        </el-form>

        <el-table max-height="900" v-loading="loading" :data="repairList " style="width: 100% ">
          <el-table-column type="selection" width="55" align="center"/>
          <el-table-column label="名称" align="center" prop="liveCodeName" style="width: 100%"/>
          <el-table-column label="活码" align="center" prop="opporId" width="220" style="width: 100%">
            <template v-slot="scope">
              <!--              <vue-qr-->
              <!--                  v-if="scope.row.liveCode"-->
              <!--                  ref="qrCode"-->
              <!--                  :text="scope.row.liveCode"-->
              <!--                  width="100"-->
              <!--                  height="100"-->
              <!--              ></vue-qr>-->
              <el-image style="width: 100px; height: 100px" @click="qrView(scope.row)"
                        :src="scope.row.liveCode"></el-image>
            </template>
          </el-table-column>
          <el-table-column label="企业标签" align="center" prop="goodsName" min-width="200" style="width: 100%">
            <template v-slot="scope">
              <el-tag v-for="item in scope.row.tags" :key="item" size="small">{{ item }}</el-tag>
            </template>
          </el-table-column>
          <el-table-column label="累计添加客户" align="center" prop="countCode" width="140" style="width: 100%">
            <template v-slot="scope">
              <div>
                <el-link type="primary" @click="showDetailList(scope.row.id)"> {{ scope.row.countCode || 0 }}</el-link>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="使用员工" align="center" prop="payTime" type="date" width="140"
                           style="width: 100%">
            <template v-slot="scope">
              <el-tag v-for="item in getEmployeeName(scope.row.userIds)" :key="item" size="small">{{ item }}</el-tag>
            </template>
          </el-table-column>
          <el-table-column label="是否推送欢迎语" align="center" prop="payTime" type="date" width="140"
                           style="width: 100%">
            <template v-slot="scope">
              <div>{{ scope.row.isPush == 1 ? '是' : '否' }}</div>
            </template>
          </el-table-column>
          <el-table-column label="创建时间" align="center" prop="createTime" type="date" width="140"
                           style="width: 100%"/>
          <el-table-column label="操作" align="center" prop="贴标签" width="142" fixed="right" style="width: 100%">
            <template v-slot="scope">

              <!--              <el-dropdown trigger="click" @command="handleCommand">-->
              <!--                <span class="el-dropdown-link">-->
              <!--                  <el-button type="text" size="mini">活码链接</el-button>-->
              <!--                  <i class="el-icon-arrow-down el-icon&#45;&#45;right"></i>-->
              <!--                </span>-->
              <!--                <el-dropdown-menu slot="dropdown">-->
              <!--                  <el-dropdown-item command="a">生成链接</el-dropdown-item>-->
              <!--                  <el-dropdown-item command="b">数据统计</el-dropdown-item>-->
              <!--                </el-dropdown-menu>-->
              <!--              </el-dropdown>-->
              <el-dropdown trigger="click" class="dropdown-right">
                <span style="color: #2b93ec">活码链接</span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item>
                    <el-link :underline="false" @click="generateLink(scope.row)">生成链接</el-link>
                  </el-dropdown-item>
                  <el-dropdown-item>
                    <el-link :underline="false" @click="dataStatistics">数据统计</el-link>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
              <el-button type="text" size="mini" @click="edit(scope.row)">编辑</el-button>
              <el-button @click="deleteCode(scope.row)" type="text" size="mini">删除</el-button>
            </template>
          </el-table-column>
        </el-table>

        <pagination
            :v-show="queryParams.total>10"
            :total="queryParams.total"
            :page.sync="queryParams.page"
            :limit.sync="queryParams.size"
            @pagination="getList"
        />
      </el-col>
    </el-row>

    <el-dialog
        title="添加详情"
        :visible.sync="detailListShow"
        :modal="false"

    >
      <!--      显示维度包括：头像/昵称，添加咨询师，添加时间-->
      <el-table
          :data="detailListData"
          style="width: 100%">
        <el-table-column
            label="客户"
            width="180">
          <template v-slot="scope">
            <div style="display: flex; align-items: center;">
              <el-avatar :src="scope.row.customerAvatar"></el-avatar>
              <div style="align-items: center;margin-left: 13px"><span>{{ scope.row.nickName }}</span></div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
            prop="followUserName"
            label="咨询师"
            width="180">
        </el-table-column>
        <el-table-column
            prop="addTime"
            label="添加时间">
        </el-table-column>
      </el-table>
      <!--      <pagination-->
      <!--          :v-show="false"-->
      <!--          :total="detailListQueryParams.total"-->
      <!--          :page.sync="detailListQueryParams.page"-->
      <!--          :limit.sync="detailListQueryParams.size"-->
      <!--          @pagination="detailListData"-->
      <!--      />-->

      <el-pagination
          :hide-on-single-page="detailListQueryParams.total<detailListQueryParams.size"
          :total="detailListQueryParams.total"
          :page-size.sync="detailListQueryParams.size"
          :page-count.sync="detailListQueryParams.page"
          @current-change="detailListDataPage"
          layout="prev, pager, next,total">
      </el-pagination>

    </el-dialog>


    <OrderRepairDetail @getList="getList" :dialogValue.sync="repairShow" :repair-id="repairId" v-if="repairShow"
                       :repairStatus.sync="repairStatus"></OrderRepairDetail>

    <el-dialog
        title="活码组"
        :visible.sync="dialogVisible"
        width="60%">

      <div>
        <el-form label-width="80px">
          <el-form-item label="组名">
            <el-input v-model="ruleForm.groupName" maxlength="16" show-word-limit/>
          </el-form-item>
        </el-form>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitGroup">确 定</el-button>
      </span>
    </el-dialog>
    <!--   活码链接   -->
    <el-dialog
        title="生成活码链接"
        :visible.sync="enableLink"
        width="30%"
        center>
      <el-form :model="posterLink.codeLink" :rules="linkRules">
        <el-form-item label="链接名称" prop="linkName" :label-width="formLabelWidth">
          <el-input v-model="posterLink.codeLink.linkName" autocomplete="off" placeholder="输入活码链接名称"
                    maxlength="15" show-word-limit style="width: 300px"></el-input>
        </el-form-item>
        <el-form-item label="有效期" prop="linkExpiryDate" :label-width="formLabelWidth">
          <el-date-picker
              v-model="posterLink.codeLink.linkExpiryDate"
              type="datetime"
              value-format="yyyy-MM-dd HH:mm:ss"
              placeholder="选择日期"
              :disabled="enableDate"
              @change="handleDateChange"
              :picker-options="{disabledDate(time) { return time.getTime() < Date.now(); }}">
          </el-date-picker>
          <el-checkbox :checked="enableDate" @change="handleCheckboxChange" class="check-left">永久有效</el-checkbox>
        </el-form-item>
        <el-form-item label="模板" :label-width="formLabelWidth">
          <el-carousel indicator-position="noneq" type="card" height="200px" :autoplay="false"
                       @change="handleChangePoster">
            <el-carousel-item v-for="item in MaterialList" :key="item.materialId">
              <el-image
                  :src="item.imgPath"
                  :fit="fits.cover"></el-image>
            </el-carousel-item>
          </el-carousel>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="addPosterCodeLink">生成海报</el-button>
          <el-button type="primary" :disabled=disabled @click="downLoadLiveCodePoster">下载海报</el-button>
  </span>
    </el-dialog>

    <el-dialog :visible.sync="qrViewSHow"
               title="预览"
               width="30%"
               center>
      <span
          style="width: 100%;  text-align: center;   display: block; color: #575757;font-size: 15px;"
      >点击二维码可直接下载</span>
      <el-image style="width: 100%" @click="downloadQrFile" :src="qrViewerUrl"></el-image>
    </el-dialog>
  </div>
</template>
<style>
.move-down-span {
  margin-bottom: 20px; /* 根据需要调整 */
}
</style>

<style scoped>

.move-down-span {
  margin-bottom: 100px; /* 根据需要调整 */
}

.button-wrapper {
  display: flex;
  justify-content: flex-end;

  .move-up-button {
    transform: translateY(-20px); /* 向上移动20像素 */
  }

}


</style>
<script>
import * as liveCodeOperate from "@/api/sphere/liveCodeLink";
import * as api from "@/utils/api";
import * as repairApi from "@/api/crm/orderRepair"
import OrderRepairDetail from "@/components/order/OrderRepairDetail.vue"
import {getUserId, getUserName} from "@/utils/auth";
import * as  accountApi from "@/api/system/account";
import * as dectApi from '@/api/system/dictionary'
import SphereSendingAdd from "@/views/sphere/SphereSendingAdd.vue";
import commonSelect from "@/components/commonSelect/index.vue";


import {
  getInserAndUpdate,
  getprivatDomainList,
  loveCodeGrouplist,
  workGetDelbyId,
  workGetIddel
} from "@/api/crm/LiveCode";
import VueQr from 'vue-qr'
import {loveCodeGroupUserList, getAllEmployeesList} from "@/components/commonSelect/api";
import {downloadUrlFile, getLiveCodeMaterialList} from "@/api/sphere/liveCodeLink";

export default {
  name: "OrderRepair",
  components: {
    OrderRepairDetail,
    VueQr,
    commonSelect
  },
  props: {},
  data() {
    return {
      allUserList: [],
      qrViewSHow: false,
      qrViewerUrl: "",
      detailListShow: false,
      detailListData: [],
      currentViewQrName: "",
      enableDate: false,
      liveCodeLinkPath: "",
      fits: ['fill', 'contain', 'cover', 'none', 'scale-down'],
      MaterialList: [],
      detailListQueryParams: {
        total: 0,
        page: 1,
        size: 10,
        current: 1
      },
      disabled: true,
      permanent: false,
      enableLink: false,
      formLabelWidth: '120px',
      deletePrivateDomain: false,
      withdraw: false,
      repairStatus: 0,
      repairId: 0,
      repairShow: false,
      payTimeArr: [],
      queryParams: {
        liveCodeName: '',
        total: 0,
        page: 1,
        size: 10,
        current: 1,
        grouptId: 0
      },
      repairList: [],
      loading: false,
      adviserList: [],
      accountList: [],
      possessList: [],
      searchRules: {
        opporId: [
          {min: 1, max: 9, message: '请输入正确的机会ID', trigger: 'blur'}
        ]
      },
      orderChannelList: [],
      orderStatusList: [
        {value: 0, label: '未提交/驳回', type: 'danger'},
        {value: 1, label: '一审审批中', type: 'warning'},
        {value: 2, label: '财务核对中', type: 'warning'},
        {value: 9, label: '完成(未录入)', type: 'success'},
        {value: 10, label: '完成(已录入)', type: 'success'},
      ],
      currentDetailList: "",
      collectionWayList: [],
      findTypeList: [
        {value: 0, label: "我的录单"},
        {value: 1, label: "审批录单"},
        {value: 2, label: "审核记录"},
        {value: 3, label: "全部"},
      ],
      payAccountArr: [],
      posterLink: {
        codeLink: {
          linkExpiryDate: ""
        },
        posterPath: "",
        liveCode: "",
        posterPosition: 0
      },
      groupData: [{
        id: 0,
        label: '全部分组',
        children: []
      }],
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      GroupOptions: [],

      dialogVisible: false,

      ruleForm: {
        groupName: ''
      },
      rules: {},
      isCheckboxChecked: false,
      createTime: [],
      linkRules: {
        linkName: [
          {required: true, message: '请填写链接名称', trigger: 'blur'}
        ],
        linkExpiryDate: [
          {required: true, message: '请选择有效期', trigger: 'change'}
        ]
      }


    };
  },
  created() {
    getAllEmployeesList().then(res => {
      if (res.success) {
        this.allUserList = res.data;
        console.log(this.allUserList)
      } else {
        this.$message.error(res.msg)
      }
    })
    this.init();
    this.getAdviserList()
    this.getPossessList()
    this.getAccountList()
    this.getLiveCodeList()
    dectApi.list({dictParent: 14}).then(resp => {
      if (resp.success) {
        this.payAccountArr = resp.data
      } else this.$message.error(resp.msg)
    })
  },
  computed: {

    SphereSendingAdd() {
      return SphereSendingAdd
    },
    userId: function () {
      return getUserId()
    }
  },
  mounted() {
    // setTimeout(() => {
    //   if (this.$refs.carousel) {
    //     this.$refs.carousel.handleMouseEnter = () => {};
    //   }
    // }, 100);
    this.getList()


    loveCodeGroupUserList().then(res => {
      // console.log(res)
      let gg_arr = []
      for (let i in (res.data.groups || [])) {
        const arr = res.data.groups[i].map(item => {
          return {
            label: item.name,
            value: item.name
          }
        })
        gg_arr.push(...arr)
      }

      this.GroupOptions = gg_arr

    })
  },
  methods: {
    getEmployeeName(ids) {
      let res = [];
      let idsArr = ids.split(",")
      for (let e of this.allUserList) {
        for (let id of idsArr) {
          if (e.userId == id.split("_")[1]) {
            res.push(e.name)
          }
        }
      }
      return res;
    },
    getUserName,
    qrView(row) {
      console.log(row)
      this.currentViewQrName = row.liveCodeName
      this.qrViewerUrl = row.liveCode;
      this.qrViewSHow = true;
    },
    downloadQrFile() {
      var image = new Image()
      // 解决跨域 Canvas 污染问题
      image.setAttribute("crossOrigin", "anonymous")
      let name = this.currentViewQrName;
      image.onload = function () {
        var canvas = document.createElement("canvas")
        canvas.width = image.width
        canvas.height = image.height
        var context = canvas.getContext("2d")
        context.drawImage(image, 0, 0, image.width, image.height)
        var url = canvas.toDataURL("image/png")
        var a = document.createElement("a")
        var event = new MouseEvent("click")
        a.download = name
        a.href = url
        a.dispatchEvent(event)
      }
      image.src = this.qrViewerUrl
    },
    detailListDataPage(page) {
      let _data = {privateDomainId: this.currentDetailList}
      this.detailListQueryParams.page = page;
      liveCodeOperate.getDetailList(_data, this.detailListQueryParams).then(resp => {
        if (resp.success) {
          this.detailListData = resp.data.records
          this.detailListQueryParams.total = resp.data.total
          this.detailListQueryParams.size = resp.data.size
        }
      })
    },
    handleClose() {

    },
    showDetailList(id) {
      this.currentDetailList = id;
      this.detailListShow = true;
      let _data = {privateDomainId: id}
      console.log(_data)
      liveCodeOperate.getDetailList(_data, this.detailListQueryParams).then(resp => {
        console.log(resp)
        if (resp.success) {
          this.detailListData = resp.data.records
          this.detailListQueryParams.total = resp.data.total
          this.detailListQueryParams.size = resp.data.size
        }
      })
    },
    // delHandleMouseEnter() {
    //   this.$refs.carousel.handleMouseEnter = () => {};
    // },
    downLoadLiveCodePoster() {
      window.location.href = this.liveCodeLinkPath
      // liveCodeOperate.downLoadPhoto(this.liveCodeLinkPath).then(res =>{
      //   if (res.success){
      //     this.$message.success(res.msg)
      //     this.enableLink = false
      //     this.posterLink.codeLink = {}
      //   }else{
      //     this.$message.error(res.msg)
      //   }
      // })
    },
    generateLink(row) {
      if (!row.liveCode) {
        this.$message(
            {
              message: "二维码为空,不能生成海报",
              type: "warning"
            }
        )
        return;
      }
      this.enableDate = false
      this.posterLink.codeLink.linkExpiryDate = this.formatTimes(new Date())
      this.disabled = true
      this.enableLink = true
      this.getMaterialList();
      console.log(row)
      this.posterLink.liveCode = row.liveCode
      this.posterLink.privateDomainId = row.id
      this.posterLink.configId = row.configId
    },
    getMaterialList() {
      getLiveCodeMaterialList().then(res => {
        this.MaterialList = res.data
      })
    },
    formatTimes(date) {
      console.log('传入的参数类型：', typeof date);
      console.log('传入的参数值：', date);
      // 获取各种类型当前时间
      var year = date.getFullYear();    // 获取年
      var month = date.getMonth() + 1;   //获取当前月
      month = month < 10 ? '0' + month : month
      var day = date.getDate();   //日
      day = day < 10 ? '0' + day : day
      // var hour = date.getHours();  //时
      // var minute = date.getMinutes();    //分
      // var second = date.getSeconds();   //秒
      // 使用三目运算符判断是否需要补 0
      var hour = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
      var minute = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
      var second = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
      console.log("日期格式化：" + `${year}-${month}-${day} ${hour}:${minute}:${second}`)
      return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
    },
    handleDateChange() {
    },
    handleCheckboxChange() {
      this.enableDate = !this.enableDate
      if (this.enableDate) {
        this.posterLink.codeLink.linkExpiryDate = "9999-12-30 00:00:00";
      } else {
        this.posterLink.codeLink.linkExpiryDate = new Date();
      }
    },
    handleChangePoster(selectedIndex) {
      console.log('选中的索引:', selectedIndex);
      const selectedItem = this.MaterialList[selectedIndex];
      console.log(selectedItem)
      this.posterLink.posterPath = selectedItem.imgPath
      this.posterLink.posterPosition = selectedItem.posterPosition
      console.log(this.posterLink)
    },
    //生成数据
    addPosterCodeLink() {
      if (this.posterLink.posterPath === "") {
        this.handleChangePoster(0)
      }
      console.log("sdfasdfasdfasdfasdfa", this.posterLink)
      liveCodeOperate.addLiveCodePoster(this.posterLink).then(res => {
        if (res.success) {
          this.$message.success("海报已生成")
          this.liveCodeLinkPath = res.msg
          console.log(res.msg)
          console.log("++++++++++++" + this.liveCodeLinkPath)
          //跳转到页面
          //console.log("链接地址：",res.data)
          //window.location.href = res.data
          //console.log("链接地址：",res.data)
          this.disabled = false
        } else {
          this.$message.error("海报生成失败")
        }
      })
    },
    changeIuput() {

    },
    getListByGroupId(data) {
      this.queryParams.grouptId = data.id
      this.getList()
      console.log(data)
    },
    append(data) {
      // console.log(data)
      this.ruleForm.groupName = data.label
      this.ruleForm.id = data.id
      this.dialogVisible = true
    },
    remove(node, data) {
      this.$confirm('确认删除？')
          .then(_ => {
            workGetIddel({
              id: data.id
            }).then(res => {
              if (res.success) {
                this.$message.success('删除成功')
                this.getLiveCodeList()
              } else {
                this.$message.error(res.msg)
              }
            })
          })
          .catch(_ => {
            console.log(_)
          });
    },
    submitGroup() {
      let params = {
        groupName: this.ruleForm.groupName
      }

      if (this.ruleForm.id) {
        params.id = this.ruleForm.id
      }
      getInserAndUpdate(params).then(res => {
        console.log(res)
        this.$message.success('操作成功')
        this.dialogVisible = false
        this.getLiveCodeList()
        this.ruleForm.groupName = ''
        this.ruleForm.id = null
      })
    },
    createTimeChange(val) {
      console.log(val)
      if (val) {
        this.queryParams.start = val[0]
        this.queryParams.finish = val[1]
      } else {
        this.queryParams.start = null
        this.queryParams.finish = null
      }
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          alert('submit!');
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    handleCommand(command) {
      if (command === 'a') {
        this.dialogVisible = true
      }
    },

    deleteCode(row) {

      this.$confirm('确认删除？')
          .then(_ => {
            this.deleteCodeReq(row.id);
          })
          .catch(_ => {
            console.log(_)
          });

    },
    deleteCodeReq(id) {
      console.log(id)
      workGetDelbyId({
        id: id
      }).then(res => {
        // console.log(res)
        if (res.success) {
          this.$message.success('删除成功')
          this.getList()
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    edit(row) {
      console.log(row)
      this.$router.push({
        path: '/sphere/ChannelAdd',
        query: {
          id: row.id
        }
      })
    },
    //数据统计
    dataStatistics() {
      this.$router.push({
        path: '/sphere/CodeLinkStatistics',
        query: {}
      })
    },
    getGetprivatDomainList() {
      getprivatDomainList().then(res => {
        console.log(res)
      })
    },
    getLiveCodeList() {
      loveCodeGrouplist().then(res => {
        // console.log(res)
        const arr = res.data?.map(item => {
          return {
            label: item.groupName,
            id: item.id
          }
        }) || []
        this.groupData[0].children = arr
      })
    },
    ChannelAdd() {
      this.$router.push('/sphere/ChannelAdd')
    },

    tableRowClassName({row, rowIndex}) {
      if (rowIndex % 1) {
        return 'warning-row';
      } else if (rowIndex % 2) {
        return 'success-row';
      }
      return 'warning-row';
    },
    // 初始化数据
    init() {
      dectApi.list({dictParent: 14}).then(resp => {
        if (resp.success) {
          this.payAccountArr = resp.data
        } else this.$message.error(resp.msg)
      })
      dectApi.list({dictParent: 15}).then(resp => {
        if (resp.success) {
          this.collectionWayList = resp.data
        } else this.$message.error(resp.msg)
      })
      dectApi.list({dictParent: 16}).then(resp => {
        if (resp.success) {
          this.orderChannelList = resp.data
        } else this.$message.error(resp.msg)
      })
    },
    repairInfo(repairId) {
      this.repairId = repairId
      this.repairStatus = 9;
      this.repairShow = true
    },
    /**
     * 修改录单
     */
    updateRepair(repairId) {
      this.repairId = repairId
      this.repairStatus = 1;
      this.repairShow = true
    },
    /**
     * 审核
     */
    examineRepair(repairId) {
      this.repairId = repairId
      this.repairStatus = 2;
      this.repairShow = true
    },
    /**
     * 提交
     */
    submitRepair(row) {
      repairApi.submitRepairId(row).then(res => {
        if (res.success) {
          this.$message.success(res.msg)
          this.getList();
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    /**
     * 撤回
     */
    backRepair(repairId) {
      repairApi.backRepair(repairId).then(res => {
        if (res.success) {
          this.$message.success(res.msg)
          this.getList();
        } else {
          this.$message.error(res.msg)
        }
      });
    },
    // 新增
    showDetail() {
      this.repairId = 0
      this.repairStatus = 0
      this.repairShow = true
    },
    //金额格式化
    numberformat(number) {
      number = (number + '').replace(/[^0-9+-Ee.]/g, '');
      var n = !isFinite(+number) ? 0 : +number,
          prec = !isFinite(+2) ? 0 : Math.abs(2),
          s = '',
          toFixedFix = function (n, prec) {
            var k = Math.pow(10, prec);
            return '' + Math.floor(n * k) / k;
          };
      s = (prec ? toFixedFix(n, prec) : '' + Math.floor(n)).split('.');
      if ((s[1] || '').length < prec) {
        s[1] = s[1] || '';
        s[1] += new Array(prec - s[1].length + 1).join('0');
      }
      return s.join('.');
    },
    /**
     * 获取唯一值配置
     */
    getPossessList() {
      api.possessList().then(res => {
        this.possessList = res.data
      })
    },
    /**
     * 获取学习顾问列表
     */
    getAdviserList: function () {
      accountApi.adviserList().then(res => {
        if (res.success) {
          this.adviserList = res.data
        }
      })
    },
    getAccountList: function () {
      accountApi.list().then(res => {
        console.log(res)
        if (res.success) {
          this.accountList = res.data
        }
      })
    },
    /** 查询参数配置列表 */
    getList() {
      this.loading = true
      if (this.payTimeArr && this.payTimeArr[0]) {
        this.queryParams.startDateTime = this.payTimeArr[0]
        this.queryParams.endDateTime = this.payTimeArr[1]
      } else {
        this.queryParams.startDateTime = null
        this.queryParams.endDateTime = null
      }
      this.queryParams['submitId'] = this.submitId
      getprivatDomainList({
        page: this.queryParams.page,
        size: this.queryParams.size
      }, this.queryParams).then(resp => {
        if (resp.success) {
          for (let i = 0; i < resp.data.records.length; i++) {
            resp.data.records[i].userName = resp.data.records[i].userName.split(",")
          }

          this.repairList = resp.data.records
          this.queryParams.total = resp.data.total
          this.queryParams.size = resp.data.size
          // this.queryParams.page = resp.data.pages
          this.loading = false
        } else {
          // console.log(resp)
          this.$message.error(resp.msg)
          this.queryParams.findType = 0
          // this.getList()
          this.loading = false
        }
      })
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.page = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm("queryForm");
      this.payTimeArr = [];
      this.queryParams.startDateTime = null;
      this.queryParams.endDateTime = null;
      this.queryParams.start = null
      this.queryParams.finish = null
      this.createTime = []
      this.handleQuery();
    },
    deleteOrderRepair(orderRepairId) {
      repairApi.deleteOrderRepair(orderRepairId).then(res => {
        if (res.success) {
          this.$message.success(`删除成功!`);
          this.getList()
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    liveCodeLink(row) {
      console.log(row)
    }
  }
};

</script>
<style>
.dropdown-right {
  margin-right: 4px;
}

.check-left {
  margin-left: 33px;
}

.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 200px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n+1) {
  background-color: #d3dce6;
}

.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}
</style>

<style lang="less" scoped>
.small-padding button {
  margin: 3px;
}

</style>
