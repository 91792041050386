import request from '@/utils/request.js';


export function loveCodeGrouplist(query) {
    return request({
        url: `${process.env.VUE_APP_BASEURL_API2}/we/work/getGroupList`,
        method: 'get',
        params: query
    })
}

export function getprivatDomainList(query, params) {
    return request({
        url: `${process.env.VUE_APP_BASEURL_API2}/we/work/getprivatDomainList`,
        method: 'post',
        params: query,
        data: params
    })
}

export function getWelcomeList(query, params) {
    return request({
        url: `${process.env.VUE_APP_BASEURL_API2}/we/work/getWelcomeList`,
        method: 'get',
        params: query,
    })
}

export function generateQRCode(query) {
    return request({
        url: `${process.env.VUE_APP_BASEURL_API2}/we/work/generateQRCode`,
        method: 'post',
        data: query,
    })
}

export function workGetBYid(query) {
    return request({
        url: `${process.env.VUE_APP_BASEURL_API2}/we/work/getBYid`,
        method: 'get',
        params: query,
    })
}

export function workGetDelbyId(query) {
    return request({
        url: `${process.env.VUE_APP_BASEURL_API2}/we/work/getDelbyId`,
        method: 'delete',
        params: query,
    })
}

export function getInserAndUpdate(query) {
    return request({
        url: `${process.env.VUE_APP_BASEURL_API2}/we/work/getInserAndUpdate`,
        method: 'post',
        data: query,
    })
}

export function workGetIddel(query) {
    return request({
        url: `${process.env.VUE_APP_BASEURL_API2}/we/work/getIddel`,
        method: 'delete',
        params: query,
    })
}






