import request from '@/utils/request.js';

const url = "/we/work";

// /**
//  * 获取待审核列表数据
//  * @returns {*}
//  * @param params
//  */
// export function listPage(params) {
//     return request({
//         url: `${url}/listPage`, method: 'get', params: params
//     })
// }
//
// /**
//  * 获取小鹅通订单列表
//  * @param params
//  * @returns {Promise<AxiosResponse<any>>}
//  */
// export function xiaoeOrderList(params) {
//     return request.post(`${url}/xiaoe-order-list`, JSON.stringify(params));
// }

export function syncCustomer(params) {

    return request({
        url: `${process.env.VUE_APP_BASEURL_API2}${url}/syncCustomerList`, method: 'post', params: params
    })
}


export function batchEditTag(params) {

    return request({
        url: `${process.env.VUE_APP_BASEURL_API2}${url}/batchEditTag`, method: 'post', data: params
    })
}

export function editTag(params) {

    return request({
        url: `${process.env.VUE_APP_BASEURL_API2}${url}/editTag`, method: 'post', data: params
    })
}


export function customerList(params) {
    return request({
        url: `${process.env.VUE_APP_BASEURL_API2}${url}/customerList?page=${params.page}&size=${params.size}`, method: 'post', data: params
    })
}

export function queryPersonTag() {
    return request({
        url: `${process.env.VUE_APP_BASEURL_API2}${url}/getPersonTag`, method: 'get'
    })
}

export function getCorpTag() {
    return request({
        url: `${process.env.VUE_APP_BASEURL_API2}${url}/getCorpTag`, method: 'get'
    })
}