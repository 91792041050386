import request from '@/utils/request.js';

const url = "/manage/crm/order-repair";

/**
 * 获取待审核列表数据
 * @returns {*}
 * @param params
 */
export function listPage(params) {
    return request({
        url: `${url}/listPage`, method: 'get', params: params
    })
}

/**
 * 获取小鹅通订单列表
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export function xiaoeOrderList(params) {
    return request.post(`${url}/xiaoe-order-list`, JSON.stringify(params));
}

/**
 * 校验订单是否存在
 * @param orderNo
 * @returns {*}
 */
export function checkOrderNo(orderNo) {
    return request({url: `${url}/check`, method: 'get', params: {orderNo: orderNo}});
}

/**
 * 撤回订单
 * @param repairId
 * @returns {*}
 */
export function backRepair(repairId) {
    return request({url: `${url}/backRepair/${repairId}`, method: 'get'});
}

/**
 * 提交补录订单:直接进入流程
 */
export function submitRepair(params) {
    return request.post(`${url}/submitRepair`, JSON.stringify(params));
}

/**
 * 审核驳回
 * @param type 1:审核驳回 2:超级管理员驳回已完成(未同步)录单
 * @param repairId
 * @param suggest
 * @param examineBackImgs
 * @param proveType
 * @returns {*}
 */
export function examineBack(type, repairId, suggest, examineBackImgs, proveTypeStr) {
    if (type === 1) {
        return request({
            url: `${url}/examineBack/${repairId}`,
            method: 'post',
            data: JSON.stringify({
                suggest: suggest,
                examineBackImgs: examineBackImgs,
                proveType: proveTypeStr
            })
        });
    } else if (type === 2) {
        return request({
            url: `${url}/examineBack/${repairId}`,
            method: 'get',
        });
    }
}

/**
 * 审核通过
 * @param repairId
 * @param suggest
 * @param examineBackImgs
 * @param proveTypeStr
 * @param status
 * @returns {*}
 */
export function examineSubmit(repairId, suggest, examineBackImgs, proveTypeStr, status) {
    return request({
        url: `${url}/examineSubmit/${repairId}`,
        method: 'post',
        data: JSON.stringify({
            suggest: suggest,
            examineBackImgs: examineBackImgs,
            proveType: proveTypeStr,
            status: status
        })
    });
}


/**
 * 审核订单
 * @param repairId
 * @returns {*}
 */
export function examineRepair(repairId) {
    return request({url: `${url}/examineRepair/${repairId}`, method: 'get'});
}

/**
 * 再次提交订单
 * @param repairId
 * @returns {*}
 */
export function submitRepairId(repairId) {
    return request({url: `${url}/submitRepairId/${repairId}`, method: 'get'});
}

/**
 * 获取详细信息
 * @param repairId
 * @returns {*}
 */
export function getRepair(repairId) {
    return request({url: `${url}/getRepair/${repairId}`, method: 'get'});
}

/**
 * 获取补录订单流水信息
 * @returns {*}
 */
export function getCount(params) {
    return request({url: `${url}/getCount`, method: 'get', params: {params: params}});
}

/**
 * 获取用户手机号
 * @returns {*}
 */
export function getUserPhone(userId) {
    return request({url: `${url}/getUserPhone/${userId}`, method: 'get'});
}


/**
 * 进入正式订单表
 * @param repairId
 * @returns {*}
 */
export function toOrderManagement(repairId) {
    return request({
        url: `${url}/toOrderManagement/${repairId}`,
        method: 'get'
    });
}

/**
 * 批量删除补录订单
 *
 * @param repairId 补录订单id
 */
export function deleteOrderRepair(repairId) {
    return request({
        url: `${url}/delete?repairId=${repairId}`,
        method: 'delete',
    });
}

/**
 * 查看订单操作日志
 *
 * @param orderId 补录订单ID
 */
export function checkLog(orderId) {
    return request({
        url: `${url}/checkLog?orderId=${orderId}`,
        method: 'GET',
    });
}

/**
 * 申诉提交订单
 */
export function complaintSubmit(repairId){
    return request({
        url: `${url}/complaint?repairId=${repairId}`,
        method: 'GET',
    });
}