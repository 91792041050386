<template>
    <div>
        <el-button      size="small" @click="open">
            {{   this.buttonLabel==null?this.selectData.length==0?mode:"已选择 "+this.selectData.length:this.buttonLabel }}
        </el-button>

        <div>
            <el-tag  v-if="showCheckedTagDiv!='hide'"
                    v-for="(item, index) in selectData" :key="item.id"
                    style="margin-right: 10px"
                    closable
                    @close="handleTagClose(index, item)"
            >
                {{ item.label || item.tagName }}
            </el-tag>
        </div>

        <el-dialog
                :title="mode"
                :visible.sync="dialogVisible"
                width="80%"
                append-to-body
                close-on-click-modal>
            <el-row :gutter="10">
                <el-col :span="12">
                    <el-input v-model="searchInput" placeholder="输入关键词" style="width: 100%" size="small"
                              @change="searchChange"></el-input>

                    <template v-if="mode === '选择员工'">
                        <el-tree ref="tree" :data="leftArrData" node-key="id" :props="defaultProps"
                                 :filter-node-method="filterNode" @node-click="handleNodeClick"
                                 @check-change="handleCheckChange" :default-expanded-keys=[1]
                                 show-checkbox style="margin-top: 10px"></el-tree>
                    </template>

                    <template v-else-if="mode === '选择标签'">
                        <div v-for="item in leftArrData" :key="item.id">
                            <div style="margin: 10px 0">{{ item.tagName }}</div>

                            <el-checkbox style="margin-bottom: 10px;" v-for="item2 in item.children" :key="item2.id"
                                         :value="selectData.indexOf(item2) > -1" :label="item2.tagName"
                                         @change="changeCheckbox($event, item2)">{{ item2.tagName }}
                            </el-checkbox>
                        </div>
                    </template>


                </el-col>

                <el-col :span="12">
                    <div
                            style="display: flex;flex-direction: row;justify-content: space-between;background: #eeeeee;padding: 10px 20px;margin-bottom: 10px">
                        <div>已选（{{ selectData.length }}）</div>
                        <div style="color: #3D86FF;cursor: pointer" @click="clearData">清空</div>
                    </div>

                    <template v-if="mode === '选择员工'">
                        <div v-for="(item, index) in selectData" :key="item.id"
                             style="display: flex;flex-direction: row;justify-content: space-between;padding: 10px 20px;margin-bottom: 10px">
                            <div>{{ item.label }}</div>

                            <div style="margin-right: 10px;cursor: pointer" @click="clearItem(index, item)">
                                <i class="el-icon-delete"/>
                            </div>
                        </div>
                    </template>

                    <template v-else-if="mode === '选择标签'">
                        <el-tag style="margin-right: 10px;margin-bottom: 10px" v-for="(item, index) in selectData"
                                :key="item.id" closable @close="clearTagData(index, item)">
                            {{ item.tagName }}
                        </el-tag>
                    </template>

                </el-col>
            </el-row>

            <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </span>
        </el-dialog>
    </div>

</template>

<script>
    import {getLableList, loveCodeGroupUserList} from "@/components/commonSelect/api";
    import {getCorpTag} from "../../api/wework/weworkCustomer";

    function generateRandomId(length) {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let result = '';
        const charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }


    function findById(data, id) {
        for (const item of data) {
            if (String(item.id) === id) {
                return item;
            } else if (item.children && item.children.length > 0) {
                const result = findById(item.children, id);
                if (result) {
                    return result;
                }
            }
        }
        return null;
    }

    export default {
        name: 'commonSelect',
        props: {
            mode: {
                type: String,
                default: null
            },
            value: {
                type: String,
                default: null
            },
            showCheckedTagDiv:{
                type:String,
                default:null
            },
            buttonLabel:{
              type:String,
              default: null
            },
            currentData:{

            }
        },
        data() {
            return {
                dialogVisible: false,

                searchInput: '',
                ArrData: [],
                defaultProps: {
                    children: 'children',
                    label: 'label'
                },

                // 当前选中的节点
                selectData: [],

                // 节点数据
                tagData: [],
                // 员工数据
                userData: []

            }
        },
        computed: {
            leftArrData() {
                return this.ArrData
            }
        },
        watch: {
            value: {
                handler(newVal) {
                    if (this.mode == "选择员工") {
                        this.setNumber()
                    } else if (this.mode == "选择标签") {
                        this.setTag()
                    }
                },
                immediate: true
            }
        },
        async mounted() {
            await this.init()
        },
        methods: {

            // 回显选择员工
            setNumber() {
                if (this.value) {

                    let ValArr = this.value.split(',')
                    this.$refs.tree && this.$refs.tree.setCheckedKeys(ValArr)

                    let CArr = []
                    ValArr.map(item => {
                        let _R = findById(this.ArrData, item)
                        if (_R) {
                            CArr.push(_R)
                        }
                    })
                    this.selectData = CArr
                } else {
                    this.$refs.tree && this.$refs.tree.setCheckedKeys([])
                }
            },

            // 回显选择标签
            setTag() {
                if (this.value) {
                    let ValArr = this.value.split(',')
                    let CArr = []
                    this.tagData.map(item => {
                        item.tags.map(item2 => {
                            if (ValArr.indexOf(String(item2.tagId)) > -1) {
                                CArr.push(item2)
                            }
                        })
                    })
                    this.selectData = CArr
                } else {
                    this.selectData = []
                }
            },

            clearTagData(index, item) {
                this.selectData.splice(index, 1)
                this.$forceUpdate()
            },
            changeCheckbox(val, item) {
                if (val) {
                    this.selectData.push(item)
                } else {
                    this.selectData = this.selectData.filter(item2 => item2.tagId !== item.tagId)
                }

            },
            handleTagClose(index, item) {
                if (this.mode === '选择员工') {
                    this.setNumber()
                } else if (this.mode === '选择标签') {
                    this.setTag()
                }

                this.selectData.splice(index, 1)

                this.submit()

                this.$forceUpdate()
            },
            submit() {
                let ids = this.selectData.map(item => item.id).join(',')
                let idsArray = this.selectData.map(item => item.id)
                if (this.mode === '选择员工') {
                    this.$emit('input', ids);
                    if (this.$refs.tree) {
                        this.$emit('getUserName', this.$refs.tree.getCheckedNodes().filter(n =>( n.isDept == 1)&&(idsArray.indexOf(n.id)!=-1)).map(n => n.label).join(","));
                    }
                } else if (this.mode === '选择标签') {
                    ids = this.selectData.map(item => item.tagId).join(',')
                    this.$emit('input', ids);
                }
                if(this.buttonLabel){
                  if(this.buttonLabel=="贴标签"){
                    this.$emit("editTag");
                    this.$emit("editTagSend",ids);
                    console.log("执行了  submit=============")
                  }else if(this.buttonLabel =="批量编辑标签"){
                    this.$emit("batchEditTag");
                  }

                }
                this.dialogVisible = false
              console.log("执行了  submit=============")
            },
            clearItem(index, item) {
                this.$refs.tree.setChecked(item.id, false, true)

                this.selectData.splice(index, 1)
            },
            clearData() {
                this.selectData = []
                this.$refs.tree && this.$refs.tree.setCheckedKeys([])
            },
            searchChange(val) {
                if (this.mode === '选择员工') {
                    this.$refs.tree.filter(val);
                } else if (this.mode === '选择标签') {
                    getCorpTag().then(res => {
                        this.ArrData = this.setTagData(res.data)
                    })
                }
            },
            filterNode(value, data) {
                if (!value) return true;
                return data.label.indexOf(value) !== -1;
            },
            handleCheckChange(data, checked, indeterminate) {

                // if (checked) {
                //   let labelName = this.selectData.map(item => item.label)
                //   // console.log(labelName, 11111111)
                //   if (labelName.indexOf(data.label) > -1) {
                //     this.$refs.tree.setChecked(data, false)
                //     this.$message.warning('请勿重复选择相同人员')
                //     return
                //   }
                // }
                if(checked){
                        let arr = this.$refs.tree.getCheckedNodes(true, false)
                        let _s = [];
                        for (let i = 0; i < arr.length; i++) {
                            let _add = true;
                            for (let j = 0; j < _s.length; j++) {
                                if (arr[i].isDept == 1 && _s[j].isDept == 1) {
                                    if (_s[j].id.split("_")[1] == arr[i].id.split("_")[1]) {
                                        _add = false;
                                        break;
                                    }
                                }
                            }
                            if (_add) {
                                if(arr[i].isDept==1){
                                    _s.push(arr[i])
                                }
                            }
                        }
                        this.selectData = _s;
                }


                // let arr = this.$refs.tree.getCheckedNodes(true, false)
                // let _s = [];
                // for (let i = 0; i < arr.length; i++) {
                //     let _add = true;
                //     for (let j = 0; j < _s.length; j++) {
                //         if (arr[i].isDept == 1 && _s[j].isDept == 1) {
                //             if (_s[j].id.split("_")[1] == arr[i].id.split("_")[1]) {
                //                 _add = false;
                //                 break;
                //             }
                //         }
                //     }
                //     if (_add) {
                //         _s.push(arr[i])
                //     }
                // }
                // this.selectData = _s;
                // console.log(this.selectData)

                // if (checked && data.isDept === 1) {
                //   this.selectData.push(data)
                // } else {
                //   this.selectData = this.selectData.filter(item => item.id !== data.id)
                // }

            },
            async init() {
                let res = null
                switch (this.mode) {
                    case '选择员工': {
                        res = await loveCodeGroupUserList()

                        this.ArrData = res.data?.deptUserList || []

                        this.setNumber()

                        break
                    }

                    case '选择标签': {
                        res = await getCorpTag()
                        this.tagData = res.data

                        this.ArrData = this.setTagData(res.data)

                        this.setTag()

                        break
                    }
                }
            },

            setTagData(data = []) {
                // 洗数据
                let _D = [...data]

                _D.forEach(item => {
                    item.tagName = item.groupName

                    item.tags.map(item2 => {
                        if (this.searchInput) {
                            if (item2.tagName.includes(this.searchInput)) {
                                if (item.children?.length > 0) {
                                    item.children.push(item2)
                                } else {
                                    item.children = [item2]
                                }
                            }
                        } else {
                            if (item.children?.length > 0) {
                                item.children.push(item2)
                            } else {
                                item.children = [item2]
                            }
                        }
                    })
                })

                return _D
            },

            handleNodeClick(data) {
            },
            async open() {
                this.dialogVisible = true


                if (this.mode === '选择员工') {
                    setTimeout(() => {
                        this.setNumber()
                    }, 500)

                } else if (this.mode === '选择标签') {
                    this.setTag()
                }

            }
        }
    }
</script>
